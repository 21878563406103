import React, { useEffect, useRef } from "react";

class FormVerification {
  static handleInput(target, onInputProvided) {
    const verificationCode = Array.from(target.parentElement.children)
      .map((input) => input.value)
      .join("");

    const nextInput = target.nextElementSibling;
    if (nextInput) {
      if (target.value) {
        nextInput.focus();
        nextInput.value && nextInput.select();
      } else {
        const previousInput = target.previousElementSibling;
        if (previousInput && previousInput.tagName === "INPUT") {
          previousInput.focus();
        }
      }
    } else {
      if (target.value) {
        onInputProvided(verificationCode); // Pass the complete verification code as a string
      }
    }
  }

  static handleBackspace(target, onInputProvided) {
    const verificationCode = Array.from(target.parentElement.children)
      .map((input) => input.value)
      .join("");

    if (target.value) {
      target.value = "";
    } else {
      const previousInput = target.previousElementSibling;
      if (previousInput && previousInput.tagName === "INPUT") {
        previousInput.focus();
        onInputProvided(verificationCode); // Pass the complete verification code as a string
      }
    }
  }

  static handleArrowLeft(target, onInputProvided) {
    const previousInput = target.previousElementSibling;
    if (previousInput) {
      previousInput.focus();
      onInputProvided(previousInput.value);
    }
  }

  static handleArrowRight(target, onInputProvided) {
    const nextInput = target.nextElementSibling;
    if (nextInput) {
      nextInput.focus();
      onInputProvided(nextInput.value);
    }
  }

  static handlePaste(event, inputs, onInputProvided) {
    const pasteText = (event.clipboardData || window.clipboardData).getData(
      "text"
    );

    inputs.forEach((input, index) => {
      input.value = pasteText[index] || "";
    });

    const verificationCode = inputs.map((input) => input.value).join("");
    onInputProvided(verificationCode); // Pass the complete verification code as a string
    event.preventDefault();
  }
}

function shortcut(element, key, handle) {
  element.addEventListener("keydown", (e) => {
    if (e.key.toLowerCase() === key) {
      handle(element);
    }
  });
}

function VerificationInput({ onInputProvided }) {
  const verificationRef = useRef(null);
  const inputsRef = useRef([]);

  useEffect(() => {
    const verification = verificationRef.current;
    const inputs = inputsRef.current;

    inputs.forEach((input, index) => {
      input.onfocus = () => input.select();
      shortcut(input, "backspace", () =>
        FormVerification.handleBackspace(input, onInputProvided)
      );
      shortcut(input, "arrowleft", () =>
        FormVerification.handleArrowLeft(input, onInputProvided)
      );
      shortcut(input, "arrowright", () =>
        FormVerification.handleArrowRight(input, onInputProvided)
      );
    });

    verification.addEventListener("input", ({ target }) =>
      FormVerification.handleInput(target, onInputProvided)
    );
    verification.addEventListener("paste", (e) =>
      FormVerification.handlePaste(e, inputs, onInputProvided)
    );
  }, [onInputProvided]);

  return (
    <div className="mt-9" ref={verificationRef}>
      <div>
        {[...Array(6)].map((_, index) => (
          <input
            key={index}
            ref={(el) => (inputsRef.current[index] = el)}
            className="bg-gray-300 font-sans text-5xl w-12 text-center m-1 focus:border-gray-950"
            type="text"
            placeholder="-"
            maxLength="1"
          />
        ))}
      </div>
    </div>
  );
}

export default VerificationInput;
