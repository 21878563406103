import Constants from "../../utils/Constants";

function UserAccount() {
  const userName = "Vicky's Workspace";
  const email = "vignesh297@gmail.com";
  return (
    <div className="font-sans">
      <div className="p-4 rounded-xl" style={{ backgroundColor: "#EEEEEE" }}>
        <div className="flex flex-row items-center content-center">
          <img
            className="basis-1/8 rounded-medium rounder-4 w-9 h-9 mx-1"
            style={{ backgroundColor: "#F5F5F5" }}
            src={Constants.ASSETS_BASE_URL + "/image/fromSimplyMinix64.png"}
            alt=""
            width="64"
            height="64"
          />
          <div className="basis-auto">
            <div className="flex flex-col my-0 mx-1">
              <h3 className="text-gray-950 font-bold text-sm w-full">
                {userName}
              </h3>
              <p className="text-gray-500 text-xs w-full">{email}</p>
            </div>
          </div>
          <div className="basis-1/8">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-9 h-9 stroke-gray-500 text-gray-200"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserAccount;
