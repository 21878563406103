import UserAccount from "../components/UserAccount";

function Main() {
  return (
    <div className="">
      <div className="flex flex-row">
        <div className=" basis-1/6">
          <UserAccount />
        </div>

        <div className="flex-auto bg-blue-400">
          <h1>Main content</h1>
        </div>
      </div>
    </div>
  );
}
export default Main;
