import axios from "axios";
import React, { useState } from "react";
import Constants from "../utils/Constants";
import Lottie from 'react-lottie';
import * as animationData1 from '../assets/workspace_animation_1.json'
import * as animationData2 from '../assets/workspace_animation_2.json'
import { useParams } from "react-router-dom";


function onCreateWorkspace() {

    let requestData = JSON.stringify({
        "organizationName": "fromSimply Workspace",
        "workspaceName": "fromSimply Workspace",
        "organizationDescription": "",
        "ownerId": ""
    });


    axios
        .post(`${Constants.BASE_URL}/workspace/v1Beta/workspace/new`, requestData)
        .then((results) => {
            return results.data;
        })
        .then((results) => {
            console.log(`result >> ${results}`);
        })
        .catch((error) => {
            // Handle error
            if (error.response) {
                console.log(error.response.data.error); // Error message in response body
            } else {
                console.log(error.message); // Error message if no response was received
            }
        })
        .finally(() => { });
}

function NewWorkspace() {

    const { uKey } = useParams()
    const [workSpaceType, setWorkspaceType] = useState(0)

    const onWokspaceTypeSelected = (type, event) => {
        setWorkspaceType(type)
    }

    const defaultOptions1 = {
        loop: true,
        autoplay: true,
        animationData: animationData1,
        rendererSettings: {
            preserveAspectRatio: 'xMin slice'
        }
    };

    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: animationData2,
        rendererSettings: {
            preserveAspectRatio: 'xMin slice'
        }
    };


    if (workSpaceType === 0) {
        return (
            <div className={""}>
                <div className={"flex flex-col p-6 lg:px-8 mx-auto items-left"}>
                    <h1 className={"text-3xl dark:text-white"}>
                        Create Workspace
                    </h1>

                    <h5 className={"text-1xl mt-8 dark:text-white"}>Lets rock, choose one to start, we will setup rest of the things for you</h5>

                    <div className={"flex mt-8 flex-col md:flex-row"}>
                        <button className={"hover:drop-shadow-lg mr-4 md:w-1/4 hover:ease-in-out duration-500 hover:shadow-gray-400 shadow-lg bg-zinc-950 dark:bg-gray-100 my-3 p-8 rounded-2xl flex flex-col items-center w-full"} onClick={onWokspaceTypeSelected.bind(this, 1)}>
                            <Lottie options={defaultOptions1} width={220} className={"my-3 p-4"} />
                            <h1 className={"mt-4 dark:text-black text-white font-bold text-3xl"}>Individual</h1>
                            <p className={"mt-2 dark:text-black text-white"}>Make most out of your personal productivity.</p>
                        </button>
                        <button className={"hover:drop-shadow-lg md:w-1/4 hover:ease-in-out duration-500 hover:shadow-gray-400 shadow-lg bg-zinc-950 dark:bg-gray-100 my-3 p-8 rounded-2xl flex flex-col items-center w-full"} onClick={onWokspaceTypeSelected.bind(this, 2)}>
                            <Lottie options={defaultOptions2} width={220}  className={"my-3 p-4"} />
                            <h1 className={"mt-4 dark:text-black font-bold text-3xl text-white"}>Team</h1>
                            <p className={"mt-2 dark:text-black text-white"}>Collabrate with your team, make it happen.</p>
                        </button>
                    </div>
                </div>
            </div>
        )
    } else if (workSpaceType === 1) {
        return (
            <div className={"container h-dvh w-full dark:bg-zinc-900 bg-slate-0"}>
                <div className={"flex flex-col p-6 lg:px-8 mx-auto items-left"}>
                    <h1 className={"text-1xl dark:text-white"}>
                        Setting up your workspace
                    </h1>
                    <h3></h3>
                </div>
            </div>
        )
    } else {
        return (
            <div className={"h-dvh w-full dark:bg-zinc-900 bg-slate-0"}>
                <div className={"flex flex-col p-6 lg:px-8 mx-auto items-left "}>
                    <h1 className={"text-3xl dark:text-white"}>
                        Create Workspace
                    </h1>

                    <h5 className={"text-1xl mt-8 dark:text-white"}>Lets rock, choose one to start, we will setup rest of the things for you</h5>

                    <div className={"flex flex-col mt-8"}>
                        <button className={"hover:drop-shadow-lg hover:ease-in-out duration-500 hover:shadow-gray-400 shadow-lg bg-zinc-950 dark:bg-gray-100 my-3 p-8 rounded-2xl flex flex-col items-center w-full"} onClick={onWokspaceTypeSelected.bind(this, 1)}>
                            <Lottie options={defaultOptions1} width={150} className={"my-3 p-4"} />
                            <h1 className={"mt-4 dark:text-black text-white font-bold text-3xl"}>Individual</h1>
                            <p className={"mt-2 dark:text-black text-white"}>Make most out of your personal productivity.</p>
                        </button>
                        <button className={"hover:drop-shadow-lg hover:ease-in-out duration-500 hover:shadow-gray-400 shadow-lg bg-zinc-950 dark:bg-gray-100 my-3 p-8 rounded-2xl flex flex-col items-center w-full"} onClick={onWokspaceTypeSelected.bind(this, 2)}>
                            <Lottie options={defaultOptions2} width={150} className={"my-3 p-4"} />
                            <h1 className={"mt-4 dark:text-black font-bold text-3xl text-white"}>Team</h1>
                            <p className={"mt-2 dark:text-black text-white"}>Collabrate with your team, make it happen.</p>
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}





export default NewWorkspace;