import { ReactComponent as AlertSvg } from "../assets/alert.svg";
import React, { useState } from "react";
import axios from "axios";
import Constants from "../utils/Constants";

class Button extends React.Component {
  handleClick = () => {
    openEmailDomain("vignesh297@gmail.com");
  };

  render() {
    return (
      <button
        className="mt-12 bg-gray-950 p-6 text-1xl text-white"
        onClick={this.handleClick}
      >
        Open email
      </button>
    );
  }
}

function openEmailDomain(email) {
  const domain = email.split("@")[1].toLowerCase();

  switch (domain) {
    case "icloud.com":
      window.open("https://www.icloud.com/mail");
      break;
    case "gmail.com":
      window.open("https://mail.google.com");
      break;
    case "hotmail.com":
      window.open("https://www.hotmail.com");
      break;
    case "zoho.com":
      window.open("https://www.zoho.com/mail");
      break;
    default:
      break;
  }
}

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [isPasswordError, setPasswordError] = useState(false);

  const onEmailChange = function (e) {
    setEmail(e.target.value);
  };

  const onPasswordChange = function (e) {
    setPassword(e.target.value);
  };

  const eventOnPasswordShow = function (e) {
    setShowPassword(showPassword ? false : true);
  };

  const eventFormSubmit = function (e) {
    e.preventDefault();

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setEmailError(true);
      return;
    } else {
      setEmailError(false);
    }

    // Password validation
    const specialCharacters =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!specialCharacters.test(password)) {
      setPasswordError(true);
      return;
    } else {
      setPasswordError(false);
    }

    makeApiCall();
  };

  function makeApiCall(e) {
    const requestData = {
      email: email,
      password: password,
    };

    axios
      .post(`${Constants.BASE_URL}/account/v1Beta/user/login`, requestData)
      .then((results) => {
        return results.data;
      })
      .then((results) => {
        console.log(`result >> ${results}`);
        setShowSuccessMessage(true);
      })
      .catch((error) => {
        // Handle error
        if (error.response) {
          if (error.response.status === 409) {
            // already an user account exist
            setShowErrorMessage(true);
            setErrorMessage(error.response.data.error);
          } else if (error.response.status === 403) {
            // user account not verified
            setShowErrorMessage(true);
            setErrorMessage(error.response.data.error);
          } else if (error.response.status === 500) {
            // some thing went wrong unable to prceed
            setShowErrorMessage(true);
            setErrorMessage(error.response.data.error);
          } else {
            // some thing went wrong unable to prceed
            setShowErrorMessage(true);
            setErrorMessage(error.response.data.error);
          }
          console.log(error.response.data.error); // Error message in response body
        } else {
          console.log(error.message); // Error message if no response was received
        }
      })
      .finally(() => {});
  }

  return (
    <div className="mt-10">
      {showSuccessMessage ? (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
          <div className="flex flex-col items-center text-center">
            <h1 className="font-serif text-4xl font-medium">
              Your new account has been created successfully.
            </h1>
            <h5 className="mt-10 font-serif text-2xl">
              We have sent an verification email to your registered email.
              Please check your email and complete the verication to continue.
            </h5>

            <Button buttonName="">Go to my mail</Button>
          </div>
        </div>
      ) : (
        <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <div className="flex flex-col items-center">
              <div>
                <img
                  className="h-auto w-auto"
                  src={
                    "https://files.fromsimply.com/image/fromSimplyMinix64.png"
                  }
                  alt=""
                />
              </div>
              <h2 className="mt-10 text-center text-3xl font-medium	 first-line:font-serif leading-9 tracking-tight text-gray-900">
                Login into your account
              </h2>
            </div>
          </div>
          <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
            <hr />
            <form className="space-y-6" onSubmit={eventFormSubmit}>
              <div>
                {showErrorMessage ? (
                  <div className="flex mt-4 flex-column border-2 p-2 rounded-md border-red-200 items-center">
                    <span className="rounded-full bg-red-200 p-2 animate-ping opacity-90 absolute ml-1"></span>
                    <span className="absolute">
                      <AlertSvg color="red"></AlertSvg>
                    </span>
                    <span className="text ml-10 align-middle justify-center text-red-500 text-sm">
                      {errorMessage}
                    </span>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={onEmailChange}
                    className={`block w-full rounded-md border ${
                      isEmailError ? "border-red-600" : "border-gray-200"
                    } borf p-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6`}
                  />
                </div>
              </div>

              {isEmailError ? (
                <p
                  style={{ marginTop: 10 }}
                  className="sm:text-sm md:text-md mt-3 dark:text-red-500 text-red-500"
                >
                  Enter valid email address!
                </p>
              ) : (
                ""
              )}

              <div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    value={password}
                    onChange={onPasswordChange}
                    className={`block w-full rounded-md border ${
                      isPasswordError ? "border-red-600" : "border-gray-200"
                    } borf p-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6`}
                  />
                </div>
              </div>

              {isPasswordError ? (
                <div className="mt-0" style={{ marginTop: 10 }}>
                  <p className="sm:text-xs md:text-md mt-1 font-bold dark:text-red-400 text-red-500">
                    Enter valid password. Password must have one Upercase, one
                    Lowercase, one Special character, one Number.
                  </p>
                </div>
              ) : (
                ""
              )}

              <div>
                <div className="mt-2">
                  <p className="content-center">
                    <input
                      type="checkbox"
                      checked={showPassword}
                      onChange={eventOnPasswordShow}
                    />
                    <span className="ml-2 text text-sm text-gray-500">
                      Show password
                    </span>
                  </p>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-gray-950 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                  Login into your account
                </button>
              </div>
            </form>

            <hr className="mt-5"></hr>

            <p className="mt-5   text-center text-sm text-gray-500">
              If you dont have an account?
              <a
                href="/signup"
                className="font-semibold mx-1 py-2 leading-6 text-gray-950 hover:text-gray-500"
              >
                Click to signup
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
