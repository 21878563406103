import React, { useState } from "react";

class Button extends React.Component {
  handleClick = () => {
    // Call the callback function passed as a prop
    if (this.props.onClick) {
      this.props.onClick();
    }
  };
  

  render() {
    const { buttonName } = this.props;

    return (
      <button
        className="mt-12 bg-gray-950 p-6 text-1xl text-white"
        onClick={this.handleClick}
      >
        {buttonName}
      </button>
    );
  }
}
export default Button;
