import "./App.css";
import Signup from "./signup/Signup.js";
import Home from "./home/Home.js";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./login/Login";
import Verification from "./accounts/verification/Verification";
import Main from "./app/main/Main";
import PageNoFound from "./otherScreens/PageNotFound.js"
import NewWorkspace from "./workspace/Workspace.js";

function App() {
  return (
    <div className="App container mx-auto h-dvh w-screen">
      <Router>
        <Routes>
          <Route exact path="/" Component={Home}></Route>
          <Route path="/signup" Component={Signup}></Route>
          <Route path="/workspace/new/:uKey" Component={NewWorkspace}></Route>
          <Route
            path="/verification/:verificationPhrase"
            Component={Verification}
          ></Route>
          <Route path="/login" Component={Login}></Route>
          <Route path="/app" Component={Main}></Route>
          <Route path="/*" Component={PageNoFound}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
