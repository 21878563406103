import React, { useRef, useState } from "react";
import VerificationInput from "../../components/VerificationInput";
import PrimaryButton from "../../components/PrimaryButton.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import Constants from "../../utils/Constants";

function Verification() {
  const { verificationPhrase } = useParams();
  const [vCode, setVCode] = useState("");
  const [isVerificationCompleted, setVerificationCompleted] = useState(false);
  const verificationCodeRef = useRef(null);
  const [isMessageVisible, setMessageVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const eventInpuChanges = (value) => {
    setVCode(value);
  };

  const onCloseMessage = () => {
    setMessageVisible(false);
  };

  const verifyMyAccount = () => {
    if (verificationCodeRef.current) {
      setVCode(verificationCodeRef.current.getValue());
    }

    axios
      .post(`${Constants.BASE_URL}/account/v1Beta/user/verify`, {
        verificationPhrase: verificationPhrase,
        verificationCode: vCode,
      })
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        console.log(response);
        if (response.result === "OK") {
          setVerificationCompleted(true);
        }
      })
      .catch((error) => {
        console.log(error.response.data);
        setMessageVisible(true);
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <div>
      <dialog
        className="fixed inset-0 p-0 lg:max-w-2xl sm:w-3/4 overflow-auto"
        open={isMessageVisible}
      >
        <div className="flex items-center justify-center">
          <div className="fixed inset-0 bg-gray-500 opacity-75"></div>
          <div className="bg-white shadow-lg p-8 w-screen z-10">
            <div className="text-lg font-medium mb-4">
              <h1 className=" font-serif text-3xl font-semibold">
                Unable to verify your account
              </h1>
            </div>
            <hr className="mb-8"></hr>
            <div className="mb-6">
              <h3>{errorMessage}</h3>
            </div>
            <div className="flex justify-end">
              <button
                className="px-4 py-2 bg-gray-950 text-white hover:bg-gray-600"
                onClick={onCloseMessage}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </dialog>

      <div className="flex flex-col justify-center p-6 lg:px-8 mx-auto items-center">
        <h1 className="inline-flex font-sans text-5xl font-medium">
          Verification
        </h1>
      </div>

      <hr></hr>
      {isVerificationCompleted ? (
        <div className="mt-12 flex flex-col justify-center p-6 lg:px-8 mx-auto items-center text-center">
          <h1 className="text-5xl font-sans mt-16">
            Your Account has been Successfully Verified 🎉.
          </h1>

          <h1 className="font-sans text-2xl mt-10 text-center">
            Welcome to our platform! We are delighted to inform you that your
            account verification process has been completed successfully. This
            means you are now an official member of our vibrant community. Get
            ready to unlock a world of exciting features, exclusive content, and
            personalized experiences.
          </h1>

          <div className="mt-12">
            <a href="/login" className="mt-12 bg-gray-950 p-4 text-1xl text-white shadow-lg hover:shadow-zinc-800 duration-300">
              Login into my account <span aria-hidden="true"></span>
            </a>
          </div>

          <h1 className="text-3xl mt-8 w-auto font-serif font-medium">
            Thank you.
          </h1>

          <img
            className="mt-6"
            src={Constants.ASSETS_BASE_URL + "/image/fromSimplyMinix128.svg"}
            alt="logo"
            name="fromSimply"
          />
        </div>
      ) : (
        <div className="flex flex-col justify-center p-6 lg:px-8 mx-auto items-center">
          <p className=" font-sans">
            Enter the verification that you hav received in your registered
            email.
          </p>

          <VerificationInput onInputProvided={eventInpuChanges} />

          <PrimaryButton
            buttonName="Verify account"
            onClick={verifyMyAccount}
          ></PrimaryButton>

          <div className="mt-10 text-center">
            <h3 className="font-sans text-2xl ">
              To ensure the security and privacy of our users, we have
              implemented a verification process. Please enter the verification
              code you received to proceed further.
            </h3>

            <hr className="mt-10 mb-10"></hr>

            <p className=" text-start">
              Why do we ask for a verification code? <br />
              At fromSimply, we prioritize the protection of our users' accounts
              and sensitive information. The verification code serves as an
              added layer of security to verify your identity and ensure that
              only authorized individuals gain access to your account. By
              requiring a verification code, we aim to prevent unauthorized
              access and protect your personal data from potential security
              threats.
              <br />
            </p>

            <p className="mt-4 text-start">
              How to obtain the verification code?
            </p>

            <p className="mt-4 text-start">
              Typically, we send the verification code to the email address or
              phone number associated with your account. Please check your inbox
              or messages for the code. If you haven't received it, you can
              request a new code by clicking on the appropriate option below.
            </p>

            <p className="mt-4 text-start">
              Entering the verification code: To continue with the verification
              process, please enter the code you received in the designated
              field above. Ensure that you input the code accurately to avoid
              any errors. Once you've entered the code, click on the "Verify"
              button to proceed. If you encounter any issues during the
              verification process or have any questions, please don't hesitate
              to reach out to our support team for assistance. We are here to
              help you!
            </p>

            <p className="mt-4 text-start">
              Important notes: The verification code is case-sensitive, so
              please enter it exactly as it appears. The code is time-limited
              for security reasons, so make sure to enter it promptly. Be
              cautious of phishing attempts and only enter the verification code
              on official fromSimply pages. Thank you for your cooperation in
              completing the verification process. We appreciate your commitment
              to maintaining a secure and trustworthy platform for all our
              users.
            </p>

            <p className="mt-4 text-start">
              Sincerely,
              <br />
              <b>The fromSimply Team</b>
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Verification;
